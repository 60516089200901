(function ($) {
  "use strict";

  var hrk = hrk || {};

  hrk = {
    /* check if it's touch device or not
---------------------------------------------*/
    touchDevice: function () {
      var check = false;
      (function (a) {
        if (
          /(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|xs700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4),
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    /* get userAgent / OS
---------------------------------------------*/
    UA: {
      getUserAgent: function () {
        return window.navigator.userAgent.toLowerCase();
      },
      getAppVersion: function () {
        return window.navigator.appVersion.toLowerCase();
      },
      getOS: function () {
        var pf = navigator.platform,
          os;
        if (pf.indexOf("Win") != -1) {
          os = "win";
        } else if (pf.match(/Mac|PPC/)) {
          os = "mac";
        } else if (pf.match(/Linux/)) {
          os = "Linux";
        } else if (pf.match(/^.*\s([A-Za-z]+BSD)/)) {
          os = RegExp.$1;
        } else if (pf.match(/SunOS/)) {
          os = "Solaris";
        } else {
          os = "N/A";
        }
        return os;
      },
      getIEVersion: function () {
        var browser = "not IE";
        var userAgent = hrk.UA.getUserAgent();
        if (userAgent.indexOf("msie") != -1) {
          browser = "ie";
          if (appVersion.indexOf("msie 6.") != -1) {
            browser = "ie6";
          } else if (appVersion.indexOf("msie 7.") != -1) {
            browser = "ie7";
          } else if (appVersion.indexOf("msie 8.") != -1) {
            browser = "ie8";
          } else if (appVersion.indexOf("msie 9.") != -1) {
            browser = "ie9";
          } else if (appVersion.indexOf("msie 10.") != -1) {
            browser = "ie10";
          }
        }
        return browser;
      },
    },

    /* get scroll position (どれだけスクロールしたかを判定)
---------------------------------------------*/
    getScrollY: function () {
      return document.documentElement.scrollTop || document.body.scrollTop;
    },

    /* get width of the screen (ブラウザの表示領域（各種バーを含まない領域）を表示するスクリプト)
---------------------------------------------*/
    getWindowW: function () {
      return document.documentElement.clientWidth;
    },

    /* get height of the screen (ブラウザの表示領域（各種バーを含まない領域）を表示するスクリプト)
---------------------------------------------*/
    getWindowH: function () {
      return document.documentElement.clientHeight;
    },

    /* check if it's Retina display or not (Firefox や IE)
---------------------------------------------*/
    retinaScreen: function () {
      if (window.matchMedia) {
        var mq = window.matchMedia(
          "only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)",
        );
        return (mq && mq.matches) || window.devicePixelRatio > 1;
      }
    },

    /* check if its parent elements have the class name (要素の親に指定のクラス名があるかどうかを調べるスクリプト)
---------------------------------------------*/
    hasParentClass: function (e, classname) {
      if (e === document) return false;
      if (classie.has(e, classname)) {
        return true;
      }
      return e.parentNode && hrk.hasParentClass(e.parentNode, classname);
    },
  };

  hrk.util = {
    /* htmlAddClass
--------------------------------------------------*/
    htmlAddClass: function () {
      var $html = $("html");
      if (hrk.touchDevice()) {
        $html.addClass("touch");
      } else {
        $html.addClass("no-touch");
      }
    },

    /* ie8用にaddEventListenerを定義
---------------------------------------------*/
    pollyEventListerner: function () {
      if (!Event.prototype.preventDefault) {
        Event.prototype.preventDefault = function () {
          this.returnValue = false;
        };
      }
      if (!Event.prototype.stopPropagation) {
        Event.prototype.stopPropagation = function () {
          this.cancelBubble = true;
        };
      }
      if (!Element.prototype.addEventListener) {
        var eventListeners = [];

        var addEventListener = function (
          type,
          listener /*, useCapture (will be ignored) */,
        ) {
          var self = this;
          var wrapper = function (e) {
            e.target = e.srcElement;
            e.currentTarget = self;
            if (listener.handleEvent) {
              listener.handleEvent(e);
            } else {
              listener.call(self, e);
            }
          };
          if (type == "DOMContentLoaded") {
            var wrapper2 = function (e) {
              if (document.readyState == "complete") {
                wrapper(e);
              }
            };
            document.attachEvent("onreadystatechange", wrapper2);
            eventListeners.push({
              object: this,
              type: type,
              listener: listener,
              wrapper: wrapper2,
            });

            if (document.readyState == "complete") {
              var e = new Event();
              e.srcElement = window;
              wrapper2(e);
            }
          } else {
            this.attachEvent("on" + type, wrapper);
            eventListeners.push({
              object: this,
              type: type,
              listener: listener,
              wrapper: wrapper,
            });
          }
        };
        var removeEventListener = function (
          type,
          listener /*, useCapture (will be ignored) */,
        ) {
          var counter = 0;
          while (counter < eventListeners.length) {
            var eventListener = eventListeners[counter];
            if (
              eventListener.object == this &&
              eventListener.type == type &&
              eventListener.listener == listener
            ) {
              if (type == "DOMContentLoaded") {
                this.detachEvent("onreadystatechange", eventListener.wrapper);
              } else {
                this.detachEvent("on" + type, eventListener.wrapper);
              }
              eventListeners.splice(counter, 1);
              break;
            }
            ++counter;
          }
        };
        Element.prototype.addEventListener = addEventListener;
        Element.prototype.removeEventListener = removeEventListener;
        if (HTMLDocument) {
          HTMLDocument.prototype.addEventListener = addEventListener;
          HTMLDocument.prototype.removeEventListener = removeEventListener;
        }
        if (Window) {
          Window.prototype.addEventListener = addEventListener;
          Window.prototype.removeEventListener = removeEventListener;
        }
      }
    },

    /* リサイズ時の間引き処理
---------------------------------------------*/
    windowResize: function (fn, interval, eventName) {
      var eventInterval = interval ? interval : 100,
        eventID = eventName ? eventName : "",
        windowWidth = $(window).width(),
        timer;

      $(window).on("resize" + eventID, function () {
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn();
        }, eventInterval);
      });
    },

    /* ページ内リンクへのスムーズスクロール
---------------------------------------------*/
    smoothScroll: function (options) {
      var options = $.extend(
        {
          offset: 0,
          speed: 500,
        },
        options,
      );
      var eventtype = hrk.touchDevice() ? "touchstart" : "click";
      var move = hrk.touchDevice()
        ? "touchstart"
        : "mousewheel DOMMouseScroll MozMousePixelScroll";
      var scr = $(
        /(webkit)[ \/]([\w.]+)/i.test(navigator.userAgent) ? "body" : "html",
      );
      $(this).on(eventtype, function () {
        event.preventDefault();
        scr.on(move, function (event) {
          event.preventDefault();
        });
        var href = $(this).attr("href");
        if ($(href).length) {
          var target = $(href == "#" || href === "" ? "html" : href);
          var position = target.offset().top - options.offset;
          scr.animate({ scrollTop: position }, options.speed, function () {
            scr.off(move);
          });
        }
        return false;
      });
    },
    smoothScrollV: function (options) {
      var options = $.extend(
        {
          offset: 0,
          speed: 500,
          easing: "easeOutExpo",
        },
        options,
      );
      var eventtype = hrk.touchDevice() ? "touchstart" : "click";
      var move = hrk.touchDevice() ? "touchstart" : "scroll";
      var scr = $(
        /(webkit)[ \/]([\w.]+)/i.test(navigator.userAgent) ? "body" : "html",
      );
      $(this).on(eventtype, function () {
        event.preventDefault();
        scr.on(move, function (event) {
          event.preventDefault();
        });
        var href = $(this).attr("href");
        if ($(href).length) {
          var target = $(href == "#" || href === "" ? "html" : href);
          var position = target.offset().top - options.offset;
          target.velocity("scroll", {
            offset: options.offset,
            duration: options.speed,
            easing: options.easing,
            complete: function () {
              scr.off(move);
            },
          });
        }
        return false;
      });
    },
    /* fadeover
--------------------------------------------------*/
    fadeover: function (options) {
      var options = $.extend(
        {
          opacity: 0.7,
          time: 500,
        },
        options,
      );
      $(this).on({
        mouseover: function () {
          $(this).stop().animate({ opacity: options.opacity }, options.time);
        },
        mouseout: function () {
          $(this).stop().animate({ opacity: 1 }, options.time);
        },
      });
    },
    fadeoverV: function (options) {
      var options = $.extend(
        {
          opacity: 0.7,
          time: 500,
        },
        options,
      );
      $(this).on({
        mouseover: function () {
          $(this)
            .velocity("stop")
            .velocity({ opacity: options.opacity }, options.time);
        },
        mouseout: function () {
          $(this).velocity("stop").velocity({ opacity: 1 }, options.time);
        },
      });
    },
  };

  hrk.durationBase = 500;
  hrk.durationSlow = 1000;
  hrk.linearEasing = "linear";
  hrk.swingEasing = "easeOutExpo";
  hrk.msWidth = 480;
  hrk.smWidth = 768;
  hrk.mdWidth = 992;
  // hrk.headerHeight = 51;
  hrk.eventtype = hrk.touchDevice() ? "touchstart" : "click";
  // hrk.imagePath = templatePath + '/assets/images/';

  hrk.pageTransitionDuration = 1000;
  hrk.attr =
    navigator.userAgent.toLowerCase().indexOf("webkit") > 0 ? "body" : "html";

  /* -------------------------------------------------------------------------------------

    companyInfo

    ------------------------------------------------------------------------------------- */

  hrk.company = {
    name: "株式会社杉田工務店",
  };

  /* -------------------------------------------------------------------------------------

    init

    ------------------------------------------------------------------------------------- */

  hrk.init = function () {
    hrk.util.htmlAddClass();
  };

  /* -------------------------------------------------------------------------------------

    open

    ------------------------------------------------------------------------------------- */

  (hrk.open = {
    init: function () {
      var _me = this;
      _me.cacheDom();
      _me.fadeOutOverlay();
    },
    /**
     * cacheDom()
     * 処理に必要なDOMを取得しキャッシュ
     *
     */
    cacheDom: function () {
      var _me = this;
      _me.loader = $("#loader");
    },
    fadeOutOverlay: function () {
      var _me = this;
      _me.loader.velocity("fadeOut", {
        duration: hrk.durationBase,
        easing: hrk.linearEasing,
        delay: 0,
      });
    },
  }),
    /* -------------------------------------------------------------------------------------

    commonUI

    ------------------------------------------------------------------------------------- */

    (hrk.commonUI = {
      init: function () {
        var _me = this;
        _me.cacheDom();
        _me.btnHover();
        _me.pageTop();
        _me.initScroll();
        _me.addClickEventForInnerLink();
        _me.textBlast();
      },
      /**
       * cacheDom()
       * 処理に必要なDOMを取得しキャッシュ
       *
       */
      cacheDom: function () {
        var _me = this;
        _me.$btn = $(".btn");
        _me.$header = $("#pageHeader");
        _me.$headerNavi = $("#headerNav");
        _me.$innerLink = $("a[href^=#]");
        _me.$pageTop = $("#pageTop");
        _me.$animateText = $(".blast-text");
      },
      btnHover: function () {
        var _me = this;
        _me.$btn.prepend(
          '<div class="hoverContainer"><div class="hoverPoint"></div></div>',
        );

        _me.$btn.hover(
          function (ev) {
            var target = $(this);
            var areaOffset = target.offset();
            var offsetTop = ev.pageY - areaOffset.top;
            var offsetLeft = ev.pageX - areaOffset.left;

            target.find(".hoverPoint").addClass("hovered").css({
              top: offsetTop,
              left: offsetLeft,
              display: "block",
            });
          },
          function (ev) {
            $(this)
              .find(".hoverPoint")
              .fadeOut("300", function () {
                $(this).removeClass("hovered");
              });
          },
        );
      },
      addClickEventForInnerLink: function () {
        var _me = this;

        _me.$innerLink.click(function (ev) {
          ev.stopPropagation();
          ev.preventDefault();

          var $this = $(this);

          var w = hrk.getWindowW();

          if (w < hrk.smWidth) {
            return _me.smoothScroll($(this));
          } else {
            // スクロール方向によって、ヘッダーの高さが変わるため、クリックした要素のoffset.topと、target要素のoffset.topを比較し、条件分岐する。
            var headerHeight = _me.$headerNavi.height();
            var thisHeight = $this.offset().top;
            var target = this.hash;
            var targetOffset = $(target).offset().top;
            if (thisHeight < targetOffset) {
              // リンク先へ移動時に、下方向へスクロールするとき
              return _me.smoothScroll($(this), headerHeight);
            } else {
              // リンク先へ移動時に、上方向へスクロールするとき
              return _me.smoothScroll(
                $(this),
                headerHeight + _me.$header.height(),
              );
            }
          }
        });
      },
      pageTop: function () {
        var _me = this;
        _me.$pageTop.click(function () {
          return (
            $(hrk.attr).velocity("scroll", {
              duration: hrk.durationSlow,
              easing: hrk.swingEasing,
            }),
            !1
          );
        });
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 400) {
            _me.$pageTop.addClass("show");
          } else {
            _me.$pageTop.removeClass("show");
          }
        });
      },
      initScroll: function () {
        var _me = this;
        var urlHash = window.location.href.split("#")[1];

        if (urlHash && $("#" + urlHash).length) {
          var headerHeight = _me.$headerNavi.height();

          $("#" + urlHash).velocity("scroll", {
            offset: -headerHeight,
            duration: hrk.durationSlow,
            easing: hrk.swingEasing,
          });
        }
      },
      smoothScroll: function ($elem, offset) {
        var $offset = offset || 0;
        var elem = $elem;
        var href = elem.attr("href");
        if ($(href).length) {
          var target = $(href === "#" || href === "" ? "html" : href);
          target.velocity("scroll", {
            offset: -$offset,
            duration: hrk.durationSlow,
            easing: hrk.swingEasing,
          });
        }
      },
      textBlast: function () {
        var _me = this;
        _me.$animateText
          .blast({
            delimiter: "character",
          })
          .wrapInner('<span class="blast-inner"></span>');

        setTimeout(function () {
          animateLowerContentsText();
        }, 250);

        function animateLowerContentsText() {
          var $blast = $(".blast");
          var $innr = $(".blast-inner");

          $blast.addClass("animated");
          $innr.addClass("animated");

          // $innr.css({
          //   left : '60px',
          //   translateZ: '0px';
          // }).velocity({
          //   translateX: "-60px"
          // },{
          //   duration: 2500,
          //   easing: hrk.linearEasing,
          //   delay: 2000
          // });
          // $blast.css({
          //   opacity: 0,
          // }).velocity({
          //   opacity: 1,
          //   rotate: 360
          // }, {
          //   duration: 2500,
          //   easing: hrk.linearEasing,
          //   delay: 2000
          // });
        }
      },
    }),
    /* -------------------------------------------------------------------------------------

    menuUI

    ------------------------------------------------------------------------------------- */

    (hrk.menuUI = {
      init: function () {
        var _me = this;
        _me.eventtype = hrk.touchDevice() ? "touchstart" : "click";
        _me.cacheDom();
        _me.animationSP();
      },
      /**
       * cacheDom()
       * 処理に必要なDOMを取得しキャッシュ
       *
       */
      cacheDom: function () {
        var _me = this;
        _me.trigger = document.getElementById("trigger");
        _me.spNavi = document.getElementById("globalNav");
        _me.$spNavi = $(_me.spNavi);
        _me.$spNaviList = _me.$spNavi
          .find(".nav__container")
          .children(".nav__item");
        _me.$spNaviInnr = _me.$spNavi.find(".nav__container");
        _me.$overlay = $("#page-overlay");
      },
      animationSP: function () {
        // スマホ画面でのメニューUIの変更
        var _me = this;

        var h = "";

        var resetMenu = function () {
          _me.$spNavi.css({
            maxHeight: 0,
          });
          classie.remove(_me.spNavi, "open");
          classie.remove(_me.trigger, "close");
        };
        var bodyClickFn = function (evt) {
          if (!hrk.hasParentClass(evt.target, "nav")) {
            resetMenu();
            document.removeEventListener(_me.eventtype, bodyClickFn);
          }
        };
        if (_me.trigger) {
          _me.trigger.addEventListener(_me.eventtype, function (ev) {
            ev.stopPropagation();
            ev.preventDefault();
            h = _me.$spNaviInnr.height();
            setTimeout(function () {
              if (classie.has(_me.trigger, "close")) {
                resetMenu();
                document.removeEventListener(_me.eventtype, bodyClickFn);
              } else {
                _me.$spNavi.css({
                  maxHeight: h,
                });
                classie.add(_me.spNavi, "open");
                classie.add(_me.trigger, "close");
                document.addEventListener(_me.eventtype, bodyClickFn);
              }
            }, 25);
          });
        }
      },
      destroy: function () {
        var _me = this;
        classie.remove(_me.spNavi, "open");
        classie.remove(_me.trigger, "close");
      },
    });

  /* -------------------------------------------------------------------------------------

    headerUI

    ------------------------------------------------------------------------------------- */

  (hrk.headerUI = {
    init: function () {
      var _me = this;
      _me.cacheDom();
      _me.scrollEffect();
    },
    /**
     * cacheDom()
     * 処理に必要なDOMを取得しキャッシュ
     *
     */
    cacheDom: function () {
      var _me = this;
      _me.$el = $("#pageHeader");
      _me.$nav = _me.$el.find("#headerNav");
    },
    scrollEffect: function () {
      var _me = this;
      //set scrolling variables
      var scrolling = false,
        previousTop = 0,
        currentTop = 0,
        scrollDelta = 10,
        scrollOffset = 100;

      $(window).on("scroll", function () {
        if (!scrolling) {
          scrolling = true;
          !window.requestAnimationFrame
            ? setTimeout(autoHideHeader, 250)
            : requestAnimationFrame(autoHideHeader);
        }
      });

      function autoHideHeader() {
        var currentTop = $(window).scrollTop();

        checkSimpleNavigation(currentTop);

        previousTop = currentTop;
        scrolling = false;
      }

      function checkSimpleNavigation(currentTop) {
        //there's no secondary nav or secondary nav is below primary nav
        if (previousTop - currentTop > scrollDelta) {
          //if scrolling up...
          _me.$el.removeClass("site__header--hidden");
        } else if (
          currentTop - previousTop > scrollDelta &&
          currentTop > scrollOffset
        ) {
          //if scrolling down...
          _me.$el.addClass("site__header--hidden");
        }
      }
    },
  }),
    /* -------------------------------------------------------------------------------------

    sidberUI

    ------------------------------------------------------------------------------------- */
    (hrk.sidebarUI = {
      init: function () {
        var _me = this;
        _me.cacheDom();
        _me.sideNavigationEffect();
      },
      /**
       * cacheDom()
       * 処理に必要なDOMを取得しキャッシュ
       *
       */
      cacheDom: function () {
        var _me = this;
        _me.$header = $("#pageHeader");
        _me.$headerNavi = $("#headerNav");
        _me.$innerLink = $("a[href^=#]");
        _me.$sideWrap = $("#sideWrap");
        _me.$sideContent = _me.$sideWrap.find("#sideContent");
      },
      sideNavigationEffect: function () {
        var _me = this;

        if (!_me.$sideContent[0]) {
          return;
        }

        var posTop = _me.$sideWrap.offset().top;

        var $navLink = _me.$sideContent.find("a[href^=#]");

        // 各コンテンツのページ上部からの開始位置と終了位置を配列に格納しておく
        var contentsArr = [];
        for (var i = 0; i < $navLink.length; i++) {
          // コンテンツのIDを取得
          var targetContents = $navLink.eq(i).attr("href");
          // ページ上部からコンテンツの開始位置までの距離を取得
          var targetContentsTop = $(targetContents).offset().top;
          // ページ上部からコンテンツの終了位置までの距離を取得
          var targetContentsBottom =
            targetContentsTop + $(targetContents).outerHeight(true) - 1;
          // 配列に格納
          contentsArr[i] = [targetContentsTop, targetContentsBottom];
        }

        // 現在地をチェックする
        function currentCheck() {
          // 現在のスクロール位置を取得（ただし、headerの高さが可変であるので、if文で条件分岐する。）
          if (_me.$header.hasClass("site__header--hidden")) {
            var windowScrolltop =
              hrk.getScrollY() + _me.$headerNavi.height() + 5; //  +5 はゆとりを持たせるため
          } else {
            var windowScrolltop =
              hrk.getScrollY() +
              _me.$headerNavi.height() +
              _me.$header.height() +
              5; // +5はゆとりを持たせるため
          }

          for (var i = 0; i < contentsArr.length; i++) {
            // 現在のスクロール位置が、配列に格納した開始位置と終了位置の間にあるものを調べる
            if (
              contentsArr[i][0] <= windowScrolltop &&
              contentsArr[i][1] >= windowScrolltop
            ) {
              // 開始位置と終了位置の間にある場合、ナビゲーションにclass="current"をつける
              $navLink.removeClass("current");
              $navLink.eq(i).addClass("current");
              i === contentsArr.length;
            }
          }
        }

        //　サイドバーを固定
        function stickSidebar() {
          var currentTop = hrk.getScrollY();
          var headerBottomPosition =
            _me.$headerNavi.height() + _me.$headerNavi.offset().top;
          var aimPos = headerBottomPosition - posTop;
          var sideWrapHeight = _me.$sideWrap.height();
          var sideContentHeight = _me.$sideContent.height();

          // console.log('スクロール量 ：　' + currentTop);
          // console.log('ヘッダーのbottom位置：　' + headerBottomPosition);
          // console.log('サイドバーの位置 : ' + _me.$sideWrap.offset().top);
          // console.log('サイドバーwrapの高さ : ' + sideWrapHeight);
          // console.log('サイドバーContentの高さ : ' + sideContentHeight);
          //
          // console.log('比較 : sideWrapHeight + _me.$sideWrap.offset().top = ' + (sideWrapHeight + _me.$sideWrap.offset().top));

          // console.log('比較 : aimPos + sideContentHeight  = '  + (aimPos +  sideContentHeight));

          if (
            headerBottomPosition > posTop &&
            sideWrapHeight + _me.$sideWrap.offset().top >=
              headerBottomPosition + sideContentHeight
          ) {
            console.log("途中");
            _me.$sideContent.addClass("stick");
            _me.$sideContent.css({
              transform: "translateY(" + aimPos + "px)",
            });
          } else if (
            sideWrapHeight + _me.$sideWrap.offset().top <
            headerBottomPosition + sideContentHeight
          ) {
            console.log("下に到達");
            _me.$sideContent.addClass("stick");
            _me.$sideContent.css({
              transform:
                "translateY(" + sideWrapHeight - sideContentHeight + "px)",
            });
          } else {
            _me.$sideContent.removeClass("stick");
            _me.$sideContent.css({
              transform: "translateY(0px)",
            });
          }
        }

        // ページ読み込み時とスクロール時に、現在地をチェックする
        $(window).on("load scroll", function () {
          currentCheck();
          stickSidebar();
        });
      },
    }),
    /* -------------------------------------------------------------------------------------

    homeUI

    ------------------------------------------------------------------------------------- */

    (hrk.homeUI = {
      init: function () {
        var _me = this;
        _me.cacheDom();
        _me.homeSlider();
      },
      /**
       * cacheDom()
       * 処理に必要なDOMを取得しキャッシュ
       *
       */
      cacheDom: function () {
        var _me = this;
        _me.$sliderContainer = $("#sliderContainer");
        _me.$mainSlider = _me.$sliderContainer.find("#mainSlider");
        _me.$backSlider = _me.$sliderContainer.find("#backSlider");
      },
      homeSlider: function () {
        var _me = this;

        _me.$mainSlider.slick({
          pauseOnHover: false,
          arrows: false,
          dots: false,
          infinite: true,
          fade: true,
          cssEase: "linear",
          autoplay: true,
          autoplaySpeed: 9000,
          speed: 1500,
        });

        _me.$backSlider.slick({
          pauseOnHover: false,
          arrows: false,
          dots: false,
          infinite: true,
          fade: true,
          cssEase: "linear",
          autoplay: true,
          autoplaySpeed: 9000,
          speed: 1500,
        });
      },
    });

  /* -------------------------------------------------------------------------------------

    worksUI

    ------------------------------------------------------------------------------------- */
  hrk.worksUI = {
    init: function () {
      var _me = this;
      _me.cacheDom();
      _me.worksSlider();
    },
    /**
     * cacheDom()
     * 処理に必要なDOMを取得しキャッシュ
     *
     */
    cacheDom: function () {
      var _me = this;
      _me.$lightbox = $(".works-list__link");
      _me.$slides = $(".ftrm");
    },
    worksSlider: function () {
      var _me = this;
      if (!_me.$slides[0]) {
        return;
      }
      _me.$lightbox.featherlight({
        targetAttr: "data-customfeatherlight",
        afterContent: function (event) {
          var $ftrm = this.$content.find(".ftrm");
          console.log(this);
          //setTimeout(function(){
          _me.fotoramaInit($ftrm);
          // }, 500);
        },
      });
      //_me.fotoramaInit(_me.$slides);
    },

    fotoramaInit: function (elem) {
      elem.fotorama({
        width: "100%",
        maxwidth: "670px",
        //ratio: 720 / 450,
        allowfullscreen: false,
        nav: "thumbs",
        transition: "crossfade",
        thumbwidth: "45px",
        thumbheight: "45px",
        // maxheight: '480px',
        arrows: true,
        click: true,
        captions: false,
        thumbborderwidth: 2,
        swipe: true,
        trackpad: true,
        // keyboard: true, // キーボードはコメント部分だけスライドするバグあり。
        spinner: {
          lines: 17,
          length: 2,
          width: 2,
          radius: 9,
          scale: 1,
          corners: 1,
          color: "#fff",
          opacity: 0.25,
          rotate: 0,
          direction: 1,
          speed: 1.7,
          trail: 60,
          fps: 20,
        },
      });
    },
  };

  /* dom ready
    --------------------------------------------------*/
  $(function () {
    // alert('現在サイトの更新作業中です。もうしばらくお待ちください。')
    hrk.init();
    hrk.open.init();
    hrk.commonUI.init();
    hrk.menuUI.init();
    hrk.headerUI.init();
    hrk.sidebarUI.init();
    hrk.homeUI.init();
    hrk.worksUI.init();
  });
})(jQuery);
